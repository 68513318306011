import React, { useEffect, useState, useCallback } from "react";
import { Trans, I18nextContext, Link } from "gatsby-plugin-react-i18next";
import "bootstrap/dist/css/bootstrap.min.css"
import Form from "react-bootstrap/Form"
import Photo from "../components/Photo";
import "../styles/purchase.scss";
import "../styles/common_style.scss";
import { navigate } from "gatsby";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { getCountry } from "../services/country-service";
import { getFieldConsoleMessages } from "../services/language-service";
import "../styles/phoneInput.scss";
import { useTranslation } from "react-i18next";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useGeoLocation } from "../services/useGeoLocation";
import * as uuid from "uuid";
import { MESSAGE } from '../constant/message-enum';
import { FIELD_NAME } from '../constant/field-name-enum';
import SuccessAlert from '../components/common/SuccessAlert';
import ErrorAlert from '../components/common/ErrorAlert';

const initialFormValue = {
  fullName: "",
  companyName: "",
  countryGuid: uuid.NIL,
  mobileNo: "",
}
const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL;
const GEOLOCATION_URL = process.env.GEOLOCATION_URL;
const ENGLISH_LANGUAGE_GUID = process.env.ENGLISH_LANGUAGE_GUID;

const Purchase = () => {
  const { language } = React.useContext(I18nextContext);
  const buildCanonicalUrl = () =>
    language === "en"
      ? GATSBY_LANDING_PAGE_URL + "/purchase/"
      : GATSBY_LANDING_PAGE_URL + `/${language}/purchase/`;

  const canonicalUrl = buildCanonicalUrl();

  const buildHrefUrl = () =>
    language === "en"
      ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
      : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + `/${language}/`))
  const hrefUrl = buildHrefUrl();

  const { t } = useTranslation();

  const [formValues, setformValues] = useState(initialFormValue);
  const [isLoading, setLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const { location } = useGeoLocation();
  const [isLoadingCountry, setCountryLoading] = useState(false);
  const [selected_dial_code, setDialCode] = useState("");
  const [geo_location_country_code, setGeoLocationCountryCode] = useState("");
  const [alert_type, setAlertType] = useState("");
  const [alert_message, setAlertMessage] = useState("");

  const getCountryData = (languageGuid) => {
    return new Promise((resolve, reject) => {
      getCountry(languageGuid)
        .then(data => {
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const getLanguageGuidByCode = async (currentLanguageCode) => {
    let languageGuid = ENGLISH_LANGUAGE_GUID;
    let langFromLocalStore = JSON.parse(localStorage.getItem('languages'));
    console.log('langFromLocalStore++++++++++++', langFromLocalStore);

    if (langFromLocalStore != undefined && langFromLocalStore.length > 0) {
      languageGuid = langFromLocalStore.find(language => language.Code == currentLanguageCode).LanguageId;
    }

    return languageGuid;
  }

  const getCountries = useCallback(async () => {
    let countryResult = [];

    try {
      setCountryLoading(true);
      let currentLanguageCode = `${language}`;
      let languageGuid = await getLanguageGuidByCode(currentLanguageCode);
      let response = await getCountryData(languageGuid);
      countryResult = response.data.getCountries.map(value => {
        return { value: value.GroupGuid, display: value.Name, isoCode: value.ISOCode }
      });
      countryResult.sort((a, b) => (a.display > b.display) ? 1 : -1);
      setCountryList(countryResult);
      setCountryLoading(false);
      return countryResult;
    } catch (error) {
      setCountryLoading(false);
    }
  }, [language]);


  useEffect(() => {
    let isApiSubscribed = true;
    let countryList = [];
    getCountries()
      .then(async (res) => {
        countryList = res;

        fetch(GEOLOCATION_URL)
          .then(data => data.json())
          .then(json => {
            let geoLocation = json;
            if (isApiSubscribed) {
              let countryCode = geoLocation.country_code;
              // let countryPhoneCode = geoLocation.country_calling_code;
              let value = { ...initialFormValue };
              setGeoLocationCountryCode(countryCode.toLowerCase());

              if (countryList !== undefined && countryList !== null && countryList.length > 0) {
                let selectedCountryList = countryList.filter((obj) => {
                  return obj.isoCode === countryCode
                });

                if (selectedCountryList.length > 0) {
                  value['countryGuid'] = selectedCountryList[0].value;
                }
              }

              // value['mobileNo'] = countryPhoneCode;
              setformValues(value);
            }
          })
          .catch(err => console.log(err));
      }).catch(err => {
        console.log(err)
      });

    return () => {
      isApiSubscribed = false;
    };
  }, [getCountries, location]);


  const handleChange = event => {
    const value = { ...formValues }
    value[event.target.name] = event.target.value
    setformValues(value)
  }

  const submitHandler = async (event) => {
    event.preventDefault();
    setAlertType("");
    setAlertMessage("");
    let currentLanguageCode = `${language}`;
    setLoading(true);

    let validationFieldsConsole = await validateInputData(formValues);

    //Throw error if validation fails
    if (validationFieldsConsole.length > 0) {
      let validationResponse = await getFieldConsoleMessages(MESSAGE.VALIDATION_EXCEPTION, currentLanguageCode, validationFieldsConsole);
      setAlertType("ERROR");
      setAlertMessage(validationResponse);
      setLoading(false);
    } else {
      localStorage.setItem("userinfo", JSON.stringify(formValues));
      setLoading(false);
      if (currentLanguageCode === "en") {
        navigate(`/emailverification`);
      } else {
        navigate(`/${language}/emailverification`);
      }
    }
  }

  const phoneInputOnChange = (value, data, event, formattedValue) => {
    const formData = { ...formValues };
    formData['mobileNo'] = '+' + value;
    setformValues(formData);
    let selected_dial_code = '+' + data.dialCode;
    setDialCode(selected_dial_code);
  }

  const validateInputData = (formValues) => {
    let inValidList = [];

    if (formValues.fullName === null || formValues.fullName === "" || formValues.fullName === undefined) {
      inValidList.push(FIELD_NAME.FULL_NAME);
    }

    if (formValues.companyName === null || formValues.companyName === "" || formValues.companyName === undefined) {
      inValidList.push(FIELD_NAME.COMPANY_NAME);
    }

    if (formValues.countryGuid === null || formValues.countryGuid === "" || formValues.countryGuid === undefined || formValues.countryGuid === uuid.NIL) {
      inValidList.push(FIELD_NAME.COUNTRY);
    }

    if (formValues.mobileNo === null || formValues.mobileNo === "" || formValues.mobileNo === undefined || formValues.mobileNo === selected_dial_code) {
      inValidList.push(FIELD_NAME.MOBILE_NUMBER);
    }

    return inValidList;
  }


  return (
    <div>
      <Helmet>
        <script
          dangerouslySetInnerHTML={{
            __html: `
        
<!-- Google Tag Manager -->

        <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TK7M58Z');</script>
        
            `,
          }}
        />

        <script
          dangerouslySetInnerHTML={{
            __html: `
        
<!-- Google Tag Manager -->

        <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TK7M58Z"
        height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>    
            `,
          }}
        />
        <meta charSet="utf-8" />
        <title>Buy License - Logipulse Software Purchase</title>

        <Link rel="canonical" href={canonicalUrl} />
        <Link rel="alternate" href={hrefUrl} hreflang={language} />
        <meta
          name="description"
          content={t("Try Logipulse software solutions and experience impeccable logistics management from a single platform. Transform your logistics operations to the next level with Logipulse solutions.")}
        />
        <meta
          name="Keywords"
          content={t("Logistics software purchase, buy logistics solutions")}
          data-react-helmet="true"
        />
      </Helmet>
      <main className="purchase-page" lang={language}>
        <div className="purchase-page-div" lang={language}>
          <div className="container">
            {
              alert_type === 'SUCCESS' ? (<SuccessAlert message={alert_message} />) : null
            }

            {
              alert_type === 'ERROR' ? (<ErrorAlert message={alert_message} />) : null
            }

            <div className="row purchase-total" lang={language}>
              <div className="col-md-6">
                <div className="contents purchasecontents">
                  <Link to="/" language={language}>
                    {" "}
                    <Photo
                      src="LogiPulse-Logo-H48.svg"
                      className="photo-size"
                      alt=""
                    />{" "}
                  </Link>
                  <p>
                    <Trans>
                      Here starts your novel opportunity to lead the
                      competition.
                    </Trans>
                    <Trans>
                      Logipulse renders you a digital transformation to your
                      Logistics operation.
                    </Trans>
                    <Trans>
                      Spread out your wings around the world and manage them
                      from a single platform.
                    </Trans>
                    <Trans>Head out of adversity!</Trans>
                  </p>
                  <Link
                    to="/login/"
                    className=" sign-up signpurchase getsignin"
                  >
                    <Trans>Sign In</Trans>
                  </Link>
                </div>
              </div>
              <div className="col-md-6">
                <div className="contents">

                  {/* <!-- multistep form --> */}
                  <div className="purchase_heading_section">
                    <div className="profile">
                      <b>
                        <Trans>Sign Up</Trans>
                      </b>
                    </div>
                    <div className="purchase_progress">
                      <ul id="progressbar_purchase">
                        <li className="active"></li>
                        <li></li>
                        <li></li>
                        <li></li>
                      </ul>
                    </div>
                  </div>

                  <fieldset disabled={isLoading}>
                    <Form onSubmit={submitHandler} autoComplete="off">
                      <Form.Group controlId="formFullName">
                        <Form.Label>
                          <Trans>Full Name</Trans><span className="mandatory"><sup>*</sup></span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="fullName"
                          className="control-label"
                          autoFocus
                          value={formValues.fullName}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>

                      <Form.Group controlId="formCompany">
                        <Form.Label>
                          <Trans>Company Name</Trans><span className="mandatory"><sup>*</sup></span>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="companyName"
                          value={formValues.companyName}
                          onChange={handleChange}
                          required
                        />
                      </Form.Group>

                      <Form.Group
                        controlId="exampleForm.ControlSelect1"
                        lang={language}
                      >
                        {isLoadingCountry && (
                          <Photo src="transparent-loader.png" className="select_control_loader" alt="" />
                        )}
                        <Form.Label>
                          <Trans>Country</Trans><span className="mandatory"><sup>*</sup></span>
                        </Form.Label>
                        <Form.Control
                          as="select"
                          required
                          name="countryGuid"
                          value={formValues.countryGuid}
                          onChange={handleChange}
                          lang={language}
                        >
                          {countryList &&
                            countryList.map(elmt => (
                              <option key={elmt.value} value={elmt.value}>
                                {t(elmt.display)}
                              </option>
                            ))}
                        </Form.Control>
                      </Form.Group>

                      <div className="margin_bottom_16">
                        <Form.Label className="phone_input_label"><Trans>Mobile Number</Trans><span className="mandatory"><sup>*</sup></span></Form.Label>
                        <PhoneInput
                          country={geo_location_country_code}
                          containerClass="my-container-class"
                          inputClass="phone_input_class"
                          required="true"
                          enableSearch="true"
                          value={formValues.mobileNo}
                          onChange={phoneInputOnChange}
                        />
                      </div>

                      <Form.Group className="display_flex justify_content_flex_end">
                        <button
                          type="submit"
                          className="submit_button_common font_12 muller_medium display_flex flex_align_center justify_center pointer position_relative"
                          disabled={isLoading}
                        ><Trans>NEXT</Trans>
                          {
                            isLoading ? (
                              <Photo src="loader_64.gif" className="spinner" alt="" />
                            ) : null
                          }
                        </button>
                      </Form.Group>
                      <br></br>
                    </Form>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer className="purchase-footer">
          <div className="purchase-footer-bottom">
            <div className="container">
              <div className="row align-content-center">
                <div className="col-md-4 power">
                  <div>
                    <Trans>Powered by</Trans>
                  </div>
                  <Photo
                    src="Stellosysyw-Logo.svg"
                    alt=""
                    className="powered_logo"
                  />
                </div>
                <div className="col-md-4 copyright">
                  <div>
                    <Trans>Copyright 2021 Stellosys</Trans>
                    <br /> <Trans>All rights Reserved.</Trans>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </main>
    </div>
  )
}

export default Purchase;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
